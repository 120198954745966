import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProjectSiteSimple from '../../components/project_site_simple'
import ProjectLinkCard from '../../components/project_link_card'

const CorMoRen = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('sga-title')} language={i18n.language} />
          <ProjectSiteSimple title="CoreMoRen Project (KFI_16-1-2017-0024)" text={t('news-coremoren-project-desc')} pic="national-research">
              <ProjectLinkCard title={t('read-more-preparation')} path="/projects/cormoren-preparations.pdf" />
              <ProjectLinkCard title={t('read-more-start')} path="/projects/cormoren-start.pdf" />
              <ProjectLinkCard title={t('read-more-ongoing')} path="/projects/cormoren-achievements.pdf" />
              <ProjectLinkCard title={t('read-more-finalization')} path="/projects/cormoren-finalization.pdf" />
          </ProjectSiteSimple>
      </Layout>
  )
}

export default CorMoRen

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "sga", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
